import momentTz from "moment-timezone";

import {
    createSquidexUrlToActivity,
    createSquidexUrlToBiography,
} from "../../../../components/pages/events/activities/activities.utils";
import { ScheduleMode } from "../../../../components/pages/events/schedule/ScheduleController";
import { Locale } from "../../../../hooks/useI18nLocaleFormat";
import { flattenObject, removeUndefined } from "../../../../utils/common";
import { AuthStore } from "../../../auth/auth.store";
import { CoreApiEventEntity } from "../../../core-api/models/events.models";
import { ActivityEntityTypeValuePrefix, InternalSlotsDiscussionValues } from "../activity/activity.consts";
import { ActivityProposalParticipationsAggregate } from "../activity/activity.models";
import { ParticipationEntityRoleValues } from "../participation/participation.consts";
import {
    ScheduleDayEntity,
    ScheduleEntity,
    ScheduleExportModel,
    ScheduleSlotEntity,
    ScheduleTrackEntity,
} from "./schedule.models";

export type ScheduleSlotEditBlockRights = {
    isGlobalSaveDisabled: boolean;
    activityContentDataEditDisabled: boolean;
    slotEditDisabled: boolean;
    broadcastDataEditDisabled: boolean;
    liveNotesDataEditDisabled: boolean;
    supportDataEditDisabled: boolean;
};

export const scheduleActions = {
    viewActions: {
        getExportData: (
            event: CoreApiEventEntity,
            schedule: ScheduleEntity,
            activities: ActivityProposalParticipationsAggregate[],
            localeAs: (id: any) => string,
            localeAsWithLang: (id: any, lang: Locale) => string,
            locale: Locale,
            timeZone: string,
        ): ScheduleExportModel[] =>
            schedule?.days
                ?.flatMap((day) =>
                    day.tracks?.flatMap((track) =>
                        (track.slots ?? []).flatMap((slot) => {
                            const aggregate = activities.find((a) => a.activity.id === slot.activityId);
                            if (!aggregate?.activity || !aggregate.participations) {
                                return undefined;
                            }

                            return aggregate.participations.map(
                                (participation) =>
                                    ({
                                        eventName: localeAsWithLang(event?.name, locale),
                                        trackNumber: track.trackNumber,
                                        day: momentTz(day.startDay).tz(timeZone).format("DD.MM.YYYY"),
                                        start: momentTz(slot.startTime).tz(timeZone).format("HH:mm"),
                                        ///...
                                        type: localeAs(
                                            `${ActivityEntityTypeValuePrefix}.${aggregate.activity.typeValue}`,
                                        ),
                                        activityId: aggregate.activity.id,
                                        language: aggregate.activity.content?.data?.language?.iv?.toUpperCase(),
                                        title: localeAsWithLang(aggregate.activity.content?.data?.title, locale),
                                        discussion_type: localeAs(
                                            InternalSlotsDiscussionValues.find(
                                                (i) =>
                                                    i.key ===
                                                    (aggregate.activity.content?.data?.internalSlots?.iv?.discussion ??
                                                        "speaker_room"),
                                            ).displayId,
                                        ),
                                        public_link: localeAsWithLang(aggregate?.activity?.links?.site, locale),
                                        squidex_activity: createSquidexUrlToActivity(aggregate?.activity?.content?.id),
                                        description: localeAsWithLang(
                                            aggregate.activity?.content?.data?.shortDescription,
                                            locale,
                                        ),
                                        role: localeAs(
                                            ParticipationEntityRoleValues().find((v) => v.key === participation?.role)
                                                .displayId as any,
                                        ),
                                        speaker: `${localeAsWithLang(
                                            participation.biography?.content?.data?.firstName,
                                            locale,
                                        )} ${localeAsWithLang(
                                            participation.biography?.content?.data?.lastName,
                                            locale,
                                        )}`,
                                        company: localeAsWithLang(
                                            participation.biography?.content?.data?.company,
                                            locale,
                                        ),
                                        squidex_participation: createSquidexUrlToBiography(
                                            participation?.biography?.content?.id,
                                        ),
                                        participant_id: participation.participant?.id,
                                    } as ScheduleExportModel),
                            );
                        }),
                    ),
                )
                .filter((a) => a),
    },
    editActions: {
        removeTrackNotEditableFields: (data: ScheduleTrackEntity): ScheduleTrackEntity =>
            removeUndefined({
                ...data,
                id: undefined,
                slots: undefined,
                dayId: undefined,
                createdAt: undefined,
                modifiedAt: undefined,
                scheduleId: undefined,
                trackNumber: undefined,
            } as ScheduleTrackEntity),
        removeSlotNotEditableFields: (data: ScheduleSlotEntity): ScheduleSlotEntity =>
            removeUndefined({
                ...data,
                id: undefined,
                scheduleId: undefined,
                dayId: undefined,
                trackId: undefined,
                createdAt: undefined,
                modifiedAt: undefined,
            } as ScheduleSlotEntity),
    },
    rightsActions: {
        scheduleSlotEditBlockRightsFn(
            mode: ScheduleMode,
            authStore: AuthStore,
            eventId: number,
        ): ScheduleSlotEditBlockRights {
            const isSupportScheduleView = mode === "support";
            const isLiveScheduleView = mode === "live";
            const isRegularScheduleView = mode === "regular" || mode === undefined;

            const globalLock = !eventId;

            // only for admin coordinator editor
            const slotEditDisabled =
                globalLock || isLiveScheduleView ? true : !authStore.isAdminCoordinatorEditor(eventId);
            // only for admin coordinator editor
            const activityContentDataEditDisabled =
                globalLock || !isRegularScheduleView ? true : !authStore.isAdminCoordinatorEditor(eventId);
            // only for admin coordinator editor
            const broadcastDataEditDisabled =
                globalLock || !isRegularScheduleView ? true : !authStore.isAdminCoordinatorEditor(eventId);
            // only for admin coordinator editor
            const liveNotesDataEditDisabled =
                globalLock || isSupportScheduleView ? true : !authStore.isAdminCoordinatorEditor(eventId);
            // only for support and admin
            const supportDataEditDisabled =
                globalLock || !isSupportScheduleView
                    ? true
                    : !(authStore.isGlobalSupportTeam || authStore.isGlobalAdmin);

            return {
                isGlobalSaveDisabled:
                    slotEditDisabled &&
                    activityContentDataEditDisabled &&
                    broadcastDataEditDisabled &&
                    liveNotesDataEditDisabled &&
                    supportDataEditDisabled,
                slotEditDisabled,
                activityContentDataEditDisabled,
                broadcastDataEditDisabled,
                liveNotesDataEditDisabled,
                supportDataEditDisabled,
            };
        },
    },
    sortSlotsListByDate: (a: ScheduleSlotEntity, b: ScheduleSlotEntity): number => {
        if (b.startTime > a.startTime) {
            return -1;
        } else {
            return 1;
        }
    },
    sortDayListByNumber: (a: ScheduleDayEntity, b: ScheduleDayEntity): number => a.dayNumber - b.dayNumber,
    sortTracksListByNumber: (a: ScheduleTrackEntity, b: ScheduleTrackEntity) => a.trackNumber - b.trackNumber,
    sanitizeScheduleForDiff: (schedule: ScheduleEntity): ScheduleEntity => {
        let o = JSON.parse(JSON.stringify(schedule));
        const keys = [
            "createdFromId",
            "id",
            "createdAt",
            "modifiedAt",
            "scheduleId",
            "dayId",
            "trackId",
            "eventId",
            "type",
            "status",
            "changeCounter",
        ];

        return flattenObject(o, keys) as ScheduleEntity;
    },
};
